import React from 'react';
import Img from 'gatsby-image';

import styles from './HeroSpace.module.scss';
import { combine, palette, css, flex } from '../../style/utils';

import ActionButton from '../ActionButton/ActionButton';
import ContentWrapper from '../ContentWrapper/ContentWrapper';
import WrappedImage from '../WrappedImage/WrappedImage';

const titleClasses = {
  main: css({
    color: palette.purple,
  }),
  subtitle: css({
    color: palette.grey,
  }),
};

class HeroSpace extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <ContentWrapper variant="light" className={styles.contentWrapper}>
        <div className={styles.featureWrapper}>
          <div className={styles.textWrapper}>
            <h1 className={combine(titleClasses.main, this.props.titleClass)}>
              {this.props.title}
            </h1>
            <h2 className={titleClasses.subtitle}>{this.props.subtitle}</h2>
            {this.props.button ? (
              <ActionButton
                variant="feature"
                modifiers={['hero']}
                className={this.props.button.className}
                link={this.props.button.link}
              >
                {this.props.button.text}
              </ActionButton>
            ) : null}
          </div>
          {this.props.image && this.props.image.fluid ? (
            <Img
              fluid={this.props.image.fluid}
              alt={this.props.image.alt}
              className={combine(
                styles.image,
                flex[1],
                this.props.image.className
              )}
            />
          ) : this.props.image && this.props.image.src ? (
            <WrappedImage
              src={this.props.image.src}
              alt={this.props.image.alt}
              wrapperClass={this.props.image.wrapperClass}
              className={combine(
                styles.image,
                flex[1],
                this.props.image.className
              )}
            />
          ) : null}
        </div>
        <div className={styles.extraChildrenWrapper}>
          {this.props.extraChildren}
        </div>
      </ContentWrapper>
    );
  }
}

export default HeroSpace;
