import React from 'react';
import { graphql } from 'gatsby';
import Img from 'gatsby-image';

import { combine, bg, txt, flex } from '../style/utils';
import { findNode } from '../utils/queryUtils';

// Components
import HeroSpace from '../components/HeroSpace/HeroSpace';
import ContentWrapper from '../components/ContentWrapper/ContentWrapper';
import ActionButton from '../components/ActionButton/ActionButton';
import styles from './integrations.module.scss';

// Integration iamges and icons

import bulletPointIcon from '../../assets/icons/bulletpoint.png';
import checkmarkIcon from '../../assets/icons/checkmark.png';

const IntegrationsPage = ({ data }) => {
  const IntegrationsData = [
    {
      name: 'Yammer',
      anchor: 'yammer',
      icon: findNode(data, 'icons', 'fluid.name', 'yammer-int-icon.png'),
      image: {
        node: findNode(data, 'images', 'fluid.name', 'yammer-int-image.png'),
        alt:
          'Image showing Yammer integrating with Flare digital signage content management system',
      },
      intro:
        "Flare's integration with Microsoft's enterprise social network is Microsoft approved to work with existing Yammer deployments. ",
      benefitsIntro: [
        'When you integrate Yammer with Flare, you can share the conversation from your Yammer platform with a wider audience. ',
        'You can customize your feeds to ensure that you are only displaying the content you want to share, without impacting the dynamic nature of the message. Using Flare with Yammer means you get more from each solution. ',
      ],
      benefits: [
        'Display messages from selected Yammer groups.',
        'Retrieve and display messages from specific users, with certain tags, or on particular topics.',
        'Find and feature messages that contain particular text.',
        'Filter messages based on positive or negative sentiment.',
        'Display posts up to a certain age. ',
        'Identify the presence of images or attachments.',
      ],
      howTo: [
        'Highlight great content on your Yammer platform. ',
        'Feature staff and team members with #recognition. ',
        "Broadcast messages from a 'Yam Jam' (an ask-me-anything Yammer event where the CEO or another member of the management team will answer employees' questions).",
        "Increase awareness of the 'Yam Jam' among employees.",
        'Broadcast company announcements automatically by following a company-wide announcements group.',
      ],
    },
    {
      name: 'Power Bi',
      anchor: 'power-bi',
      icon: findNode(data, 'icons', 'fluid.name', 'powerbi-int-icon.png'),
      image: {
        node: findNode(data, 'images', 'fluid.name', 'powerbi-int-image.png'),
        alt:
          'Image showing Microsoft Power Bi integrating with Flare digital signage content management system',
      },
      intro:
        "Microsoft's big-data analytics enables you and your staff to keep an eye on those all-important numbers. ",
      benefitsIntro: [
        'When you team the dynamic capabilities of Flare with the impressive data assimilation power of Power BI, Flare can help your business run efficiently and on target. By keeping your staff informed of real-time situations, Flare can help ensure you are all working to the same goal and are aware of any issues or situations that may arise. ',
      ],
      benefits: [
        'Display your Power BI Dashboard on your screens with just a few clicks.',
        'Automatically update data in real-time.',
        "Share important information with staff who don't typically use screens.",
        'Have confidence in 99.9% uptime.',
        'Automatic recovery in the event of power or network loss without manual intervention. ',
      ],
      howTo: [
        'Displaying operating data to identify bottlenecks, quantities processed, or issues on a manufacturing line.',
        'Highlighting progress towards budgetary or production objectives. ',
        'Demonstrating marketing information, such as data from google analytics.',
        'Illustrate critical business info on an always-on executive dashboard for senior management. ',
        'Shine a light on the sales pipeline to share closed sales and those on the horizon.',
      ],
    },
    {
      name: 'Social Media Platforms',
      anchor: 'social-media',
      image: {
        node: findNode(data, 'images', 'fluid.name', 'social-int-image.png'),
        alt:
          'Image showing Flare content management system integrating with various social networks such as Twitter and Facebook',
      },
      intro:
        'Flare can integrate with the leading social media platforms such as Twitter, Facebook, Instagram and LinkedIn, so you can share your favourite social media messages with the people that make it happen. ',
      benefitsIntro: [
        'Sharing social media messages on Flare can improve morale among employees, or share important customer feedback. ',
        'Illustrating how your business interacts within the wider community by sharing staff fundraisers, praise, unusual stories or just funny photos, make the workplace an enjoyable place to be. It can give employees sight of the end goal. ',
      ],
      benefits: [
        'Display messages from selected pages.',
        'Retrieve and display messages from specific users, with defined tags, or on particular topics.',
        'Find and feature messages that contain certain text.',
        'Filter messages based on positive or negative sentiment.',
        'Display messages posted within a set number of hours or days.',
        'Identify and display images or attachments.',
      ],
      howTo: [
        'Share the results of a customer Facebook survey with your staff. ',
        'Display customer feedback from Twitter or Facebook. ',
        'Highlight hacks from Instagram that use your products.',
        'Share news and photos from the feeds of causes you support.',
        "Show images on Twitter, Facebook or Instagram of events you've sponsored, or attended. ",
        "Promote a #campaign you're running on Twitter.",
      ],
    },
  ];

  return (
    <div>
      <HeroSpace
        titleClass={styles.heroTitle}
        title="Power up Flare with Integrations"
        // subtitle=""
        image={{
          fluid: data.heroImage.fluid,
          alt:
            'Image showing laptop with social media and other service icons surrounding it',
          className: styles.heroGraphic,
        }}
        extraChildren={
          <div
            className={combine(
              flex.col,
              flex.aic,
              txt.center,
              txt.darkGrey,
              styles.heroExtra
            )}
          >
            <h5>
              Flare can integrate with your favourite external services to add
              even more value to your digital signage.
            </h5>
            <p>
              Integrations enable you to display real-time data from third
              parties in a curated, pre-approved feed, or a live feed, quickly
              and easily. So, if you want to give employees a pat on the back by
              passing on customer feedback, or a heads up on the price of oil,
              integrations are a great way to drive engagement and easily share
              with your staff and customers the information they want to see.
            </p>

            <p>
              Below are some of the more popular integrations among our
              customers. But, if you&apos;re looking for something else on your
              digital signage, give us a call and we&apos;ll see if we can make
              it happen.
            </p>
          </div>
        }
      />
      {IntegrationsData.map((data, i) => (
        <div key={i}>
          <ContentWrapper backgroundClasses={bg.white}>
            <section
              className={combine(
                i % 2 ? flex.row : flex.rowReverse,
                styles.integrationSection
              )}
            >
              <div
                className={combine(flex.col, styles.integrationText, flex[1])}
              >
                <a href={`#${data.anchor}`} name={data.anchor}>
                  <h5 className={combine(flex.row, flex.aic)}>
                    {data.icon ? (
                      <Img
                        fluid={data.icon.fluid}
                        className={styles.icon}
                        alt={`${data.name} icon`}
                      />
                    ) : null}
                    {data.name}
                  </h5>
                </a>
                <p>{data.intro}</p>
                <p className={txt.bold}>Why integrate with {data.name}?</p>
                {data.benefitsIntro.map((txt, i) => (
                  <p key={i}>{txt}</p>
                ))}
                <ul>
                  {data.benefits.map((benefit, i) => (
                    <li
                      key={i}
                      style={{ listStyleImage: `url(${bulletPointIcon}) ` }}
                    >
                      {benefit}
                    </li>
                  ))}
                </ul>
              </div>
              <Img
                className={combine(styles.integrationImage, flex[1])}
                // className={flex[1]}
                fluid={data.image.node.fluid}
                alt={data.image.alt}
              />
            </section>
          </ContentWrapper>

          <ContentWrapper
            backgroundClasses={bg.lightBlue}
            className={combine(flex.col, flex.aic, txt.center)}
          >
            <h6 className={txt.darkGrey}>
              Ways you could use a {data.name} integration?
            </h6>
            <ul
              className={combine(
                styles.waysToUseList,
                flex.row,
                flex.wrap,
                txt.left
              )}
            >
              {data.howTo.map((way, i) => (
                <li
                  className={styles.wayToUseItem}
                  style={{ listStyleImage: `url(${checkmarkIcon}) ` }}
                  key={i}
                >
                  {way}
                </li>
              ))}
            </ul>
          </ContentWrapper>
        </div>
      ))}
      <ContentWrapper variant="dark">
        <div className={combine(txt.center, flex.col, flex.aic)}>
          <h3>Start a free trial of Flare today</h3>
          <h4>No credit card required</h4>
          <ActionButton
            className={styles.seeItInActionButton}
            variant="secondary"
            modifiers={['hero']}
            link="/contact?type=trial"
          >
            Request a Trial
          </ActionButton>
        </div>
      </ContentWrapper>
    </div>
  );
};

export default IntegrationsPage;

export const pageQuery = graphql`
  query images {
    heroImage: imageSharp(
      fluid: { originalName: { regex: "/integration-hero[.]png/" } }
    ) {
      fluid(maxWidth: 1330, quality: 85, traceSVG: { color: "#ffffff" }) {
        ...GatsbyImageSharpFluid_withWebp_noBase64
      }
    }
    icons: allImageSharp(
      filter: { fluid: { originalName: { regex: "/int-icon.png/" } } }
    ) {
      edges {
        node {
          fluid(maxWidth: 130, quality: 85) {
            name: originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
    images: allImageSharp(
      filter: { fluid: { originalName: { regex: "/int-image.png/" } } }
    ) {
      edges {
        node {
          fluid(maxWidth: 1108, quality: 100) {
            name: originalName
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
    }
  }
`;
